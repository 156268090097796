'use strict';

Gri.module({
  name: 'carousel-alpha',
  ieVersion: null,
  $el: $('.carousel-alpha'),
  container: '.carousel-alpha',
  fn: function () {

    // region variables
    const $this = this.$el;
    let option = {
      items: 1,
      dots: true,
      autoplay: true,
      autoplayTimeout: $this.data('time'),
      autoplaySpeed: 500,
      navSpeed: 500,
      dotsSpeed: 500,
      loop: true,
      animateOut: 'slideOutDown',
      animateIn: 'slideInDown',
      mouseDrag: false,
      touchDrag: false,
      lazyLoad: true,
      navText: ['<i class="icon-arrow-up"></i>', '<i class="icon-arrow-down"></i>'],
      responsive: {
        992: {
          nav: true
        }
      }
    };
    // endregion

    // region run
    resizeController();
    $this.owlCarousel(option);
    $(window).on('resize', resizeController);
    // endregion

    // region events

    // endregion

    // region events functions

    // endregion

    // region functions
    function resizeController() {
      let windowH = $(window).height();
      let headerH = $('.header-alpha').outerHeight();
      $this.height(windowH - headerH);
    }
    // endregion
  }
});
